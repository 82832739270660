<script>
import Vue from "vue";
import Cookies from "js-cookie";
import ZendeskService, { ZendeskCookieID } from "@/services/zendesk.service";
import WithAppBar from "@/layouts/WithAppBar.vue";

export default Vue.extend({
  name: "ZendeskSSO",
  components: { WithAppBar },
  data: () => {
    return {
      errors: []
    };
  },
  async mounted() {
    try {
      await ZendeskService.setup();
      // small trick to make user think the system is working hard
      setTimeout(() => window.location.assign(this.zendeskLink), 1000);
    } catch (e) {
      this.errors.push(e);
    }
  },
  computed: {
    zendeskLink() {
      const zendeskJwtToken = Cookies.get(ZendeskCookieID);
      const url = `https://personalisedproplan.zendesk.com/access/jwt?jwt=${zendeskJwtToken}`;
      if (this.$route.query.return_to) {
        return `${url}&return_to=${this.$route.query.return_to}`;
      }
      return url;
    }
  }
});
</script>

<template>
  <WithAppBar>
    <b-container data-cy="loading-spinner">
      <b-row>
        <b-col v-if="errors.length === 0" class="text-center">
          <b-spinner :loading="true" />
          <div>
            <h2>Redirecting to Help Centre...</h2>
          </div>
        </b-col>
        <b-col v-else class="text-center">
          <div>
            <h2>Can't redirect, reason: {{ errors.join(", ") }}</h2>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </WithAppBar>
</template>
